import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { LandingComponent } from '@clinician/features/landing/landing.component';
import { NavigationPanelComponent } from '@clinician/features/navigation-panel/navigation-panel.component';
import { NotFoundComponentComponent } from '@clinician/features/not-found-component/not-found-component.component';
import { environment } from '@clinician_environment';
import {
  NcButtonComponent,
  NcIconComponent,
  NcIconService,
  NcLogoComponent,
  NcNavButtonComponent,
} from '@neurocare/ui';
import {
  ErrorHandlerService,
  ILoggingService,
  INSTRUMENTATION_KEY,
  LoggingService,
  MockLoggingService,
} from '@neurocare/utils';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { msalConfig, protectedResources } from './auth-config';

const authimports = [
  MsalModule.forRoot(
    new PublicClientApplication(msalConfig),
    {
      // routing guard configuration
      interactionType: InteractionType.Redirect,
    },
    {
      // MSAL interceptor configuration
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        [
          protectedResources.clinicianApi.endpoint,
          protectedResources.clinicianApi.scopes,
        ],
      ]),
    },
  ),
];

const authProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  MsalGuard,
];

const authBootstrap = [MsalRedirectComponent];

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

@NgModule({
  declarations: [
    AppComponent,
    NavigationPanelComponent,
    NotFoundComponentComponent,
    LandingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSidenavModule,
    MatDividerModule,
    HttpClientModule,
    FormsModule,
    MatTabsModule,
    environment.b2cTenant.enabled ? [...authimports] : [],
    NcButtonComponent,
    NcIconComponent,
    NcNavButtonComponent,
    NcLogoComponent,
  ],
  providers: [
    environment.b2cTenant.enabled ? [...authProviders] : [],
    {
      provide: INSTRUMENTATION_KEY,
      useValue: environment.appInsights.instrumentationKey,
    },
    {
      provide: ILoggingService,
      useFactory: (instrumentationKey: string) => {
        environment.useAppInsights
          ? new LoggingService(instrumentationKey)
          : new MockLoggingService();
      },
      deps: [INSTRUMENTATION_KEY],
    },
    environment.production
      ? [{ provide: ErrorHandler, useClass: ErrorHandlerService }]
      : [],
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    NcIconService,
  ],
  bootstrap: [
    AppComponent,
    environment.b2cTenant.enabled ? [...authBootstrap] : [],
  ],
})
export class AppModule {}
