<div class="flex h-screen flex-col items-center justify-between">
  <nc-logo variant="image" size="md" class="mt-4" />
  <div class="flex w-full flex-col gap-6">
    @if (patientsAccess$ | async) {
      <button
        nc-nav-button
        [icon]="NcIcon.Patient"
        routerLink="patient"
        routerLinkActive="active_link"
      >
        Patients
      </button>
    }
    @if (
      {
        clinicReport: clinicReportAccess$ | async,
        baseVersion: baseVersion$ | async,
      };
      as visibility
    ) {
      @if (visibility.clinicReport && !visibility.baseVersion) {
        <button
          nc-nav-button
          [icon]="NcIcon.File"
          routerLink="clinic-report"
          routerLinkActive="active_link"
        >
          Clinic Report
        </button>
      }
    }
    <button
      nc-nav-button
      [icon]="NcIcon.Settings"
      routerLink="setting"
      routerLinkActive="active_link"
    >
      Settings
    </button>
  </div>
  <div class="mb-4 w-full">
    <button nc-nav-button [icon]="NcIcon.Logout1" (click)="logout()">
      Logout
    </button>
  </div>
</div>
