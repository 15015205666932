import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Size } from '../../types/size';

export type NcLogoVariant = 'image' | 'os' | 'text';
export type NcLogoTheme = 'default' | 'white' | 'dark';
export type NcLogoSize = Extract<Size, 'sm' | 'md' | 'lg'>;

const LOGO_THEME_AVAILABILITY: Record<NcLogoVariant, NcLogoTheme[]> = {
  image: ['default', 'white'],
  os: ['default', 'white', 'dark'],
  text: ['default', 'white', 'dark'],
};

const LOGO_SIZES: Record<NcLogoVariant, Record<NcLogoSize, number>> = {
  image: {
    sm: 25,
    md: 50,
    lg: 75,
  },
  os: {
    sm: 150,
    md: 250,
    lg: 350,
  },
  text: {
    sm: 120,
    md: 200,
    lg: 280,
  },
};

@Component({
  selector: 'nc-logo',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logo.component.html',
})
export class NcLogoComponent implements OnChanges {
  @Input() variant: NcLogoVariant = 'image';
  @Input() theme: NcLogoTheme = 'default';
  @Input() size: NcLogoSize = 'md';

  @HostBinding('class') elementClasses = '';
  @HostBinding('style.width.px') logoWidth = 0;
  logoPath = '';
  effectiveTheme: NcLogoTheme = 'default';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['variant'] || changes['theme'] || changes['size']) {
      this.updateProperties();
    }
  }

  private updateProperties(): void {
    this.effectiveTheme = this.theme;
    if (
      this.variant &&
      !LOGO_THEME_AVAILABILITY[this.variant].includes(this.theme)
    ) {
      console.warn(
        `Theme '${this.theme}' is not available for logo variant '${this.variant}'. Using default theme instead.`,
      );
      this.effectiveTheme = 'default';
    }

    this.elementClasses = `block logo-${this.variant} theme-${this.effectiveTheme} size-${this.size}`;
    this.logoWidth =
      this.variant && this.size ? LOGO_SIZES[this.variant][this.size] : 0;
    const themeSuffix =
      this.effectiveTheme !== 'default' ? `-${this.effectiveTheme}` : '';
    this.logoPath = this.variant
      ? `assets/logos/${this.variant}-logo${themeSuffix}.svg`
      : '';
  }
}
